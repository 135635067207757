<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
//import coachs from "@/coachs.json";
import chat from "@/views/pages/chat/chat.vue";
import { DatePicker } from "v-calendar";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader, chat, DatePicker },
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.coachLogged = sessionStorage.getItem("authUser");
    this.idUser = this.$route.params.id;
    this.getUser();
    this.getEvolutions();
    this.getHistoricWorkouts();
    this.getFormCoaching();
    this.getProgrammesCoaching();
    this.getOptionsCoach();
  },
  watch: {
    user: {
      handler(newVal) {
        document.getElementById("savemodifications").style.display = "block";
        console.log("property updated", newVal);
      },
      deep: true,
    },
    formCoaching: {
      handler(newVal) {
        document.getElementById("savemodificationsformcoaching").style.display =
          "block";
        console.log("property updated", newVal);
      },
      deep: true,
    },
  },
  data() {
    return {
      title: "Profile",
      coachLogged: "",
      isLoading: false,
      idUser: null,
      fcmax: null,
      slowzone: 0,
      brulegraisse: 0,
      conditionphysique: 0,
      slowzonepourcentage: 0,
      brulegraissepourcentage: 0,
      conditionphysiquepourcentage: 0,
      user: null,
      evolutions: [],
      formCoaching: null,
      forceReloadChat: 0,
      selectEvolutionDelete: null,
      dateNewEvolution: null,
      poidsNewEvolution: null,
      photosNewEvolution: [],
      adding_new_evolutions: false,
      sending_ticket: false,
      sending_notif: false,
      coach: null,
      uploadValue: 0,
      totalTickets: 0,
      first_weight: null,
      first_weight_date: null,
      first_photo: null,
      last_weight: null,
      last_weight_date: null,
      besoin_kcal: 0,
      last_photo: null,
      objectif: null,
      last_open: null,
      last_workout: null,
      programmes: [],
      workouts: [],
      nutrition: null,
      ajust_nutrition: 0,
      ajust_nutrition_modified: false,
      saving_ajust_nutrition: false,
      saving_modification: false,
      saving_modif_evolution: false,
      saving_modification_form: false,
      regenerating_menu: false,
      regime_special_user: [],
      optionsCoach: [{ value: null, text: "NO" }],
      optionsRole: ["basic", "premium", "coach", "administrator"],
      selectedCoach: null,
      notes_coach: "",
      saving_note: false,
      saving_date: false,
      saving_coach: false,
      saving_new_diet: false,
      end_subscription: null,
      error_saving_note: false,
      paiements: [],
      modelConfig2: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      optionsChartBpm: {
        chart: {
          type: "line",
          width: "100%",
          id: "chart-bpm",
        },
        yaxis: {
          min: 0,
          max: 250,
        },
        xaxis: {
          labels: {
            formatter: function (value, timestamp) {
              if (timestamp % 60 == 0) return timestamp / 60 + "min";
              else return "";
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#000000",
              label: {
                borderColor: "#000000",
                style: {
                  color: "#fff",
                  background: "#000000",
                },
                text: "FCMAX",
              },
            },
            {
              y: 0,
              borderColor: "#e8a11c",
              label: {
                borderColor: "#e8a11c",
                style: {
                  color: "#000000",
                  background: "#e8a11c",
                },
                text: "Brule-graisse",
              },
            },
            {
              y: 0,
              borderColor: "#e83e1c",
              label: {
                borderColor: "#e83e1c",
                style: {
                  color: "#000000",
                  background: "#e83e1c",
                },
                text: "Brule-graisse + condition physique",
              },
            },
          ],
        },
      },
      optionsChartWeights: {
        chart: {
          type: "line",
          width: "100%",
          id: "chart-weights",
        },
        yaxis: {
          min: 0,
          max: 100,
        },
        annotations: {
          yaxis: [
            {
              y: 50,
              borderColor: "#000000",
              label: {
                borderColor: "#000000",
                style: {
                  color: "#fff",
                  background: "#000000",
                },
                text: "Objectif",
              },
            },
          ],
        },
      },
      seriesChartBpm: [
        {
          name: "bpm",
          type: "line",
          data: [],
        },
      ],
      seriesChartWeights: [
        {
          name: "weights",
          type: "line",
          data: [],
        },
      ],
      appPayments: null,
      regimes: ["Vegana", "Vegetariana", "Senza glutine", "Senza lattosio"],
      jours: {
        0: "lundi",
        1: "mardi",
        2: "mercredi",
        3: "jeudi",
        4: "vendredi",
        5: "samedi",
        6: "dimanche",
      },
      repas: {
        0: "breakfast",
        1: "collation_matin",
        2: "dejeuner",
        3: "collation_aprem",
        4: "diner",
      },
      sexe: ["Male", "Female"],
      niveaux: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      modelConfig: {
        type: "number",
      },
      items: [
        {
          text: "Contacts",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      attrsWorkout: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      locale: ["it", "fr", "es", "us", "br"],
      username_paypal1: "contact_api1.vicodellaforma.com",
      pass_paypal1: "6P3DADEXDQHSNX9B",
      api_signature1:
        "ABlLYlJXrwUWDhF71SK.kcrBWPRzANI9.ePZzQDvqWJV4LyGiGfF0EET",
      username_paypal2: "contact_api1.gabriellavico.com",
      pass_paypal2: "FCVTDCSE4R92BDZX",
      api_signature2:
        "AY.I3TgxD0nb5Y9GP.1e1R63HH8qAJqA2w3GkGq76Mm5jFRr08Ox67HT",
      //vieux stripe (contact@proteineandco.fr)
      api_stripe1:
        "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",
      //Stripe italie (contact@vicodellaforma.com)
      api_stripe2:
        "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",
      //Stripe Chypre (contact@gabriellavico.com)
      api_stripe3:
        "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",
      //Stripe Chypre Thrivecart
      api_stripe4:
        "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",
        //Stripe Chypre snella
      api_stripe5:
        "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",
    };
  },
  methods: {
    reRender() {
      console.log("on refresh la page");
      window.location.reload();
    },
    saveModificationUser() {
      this.saving_modification = true;
      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .update(this.user)
        .then(() => {
          this.saving_modification = false;
          document.getElementById("savemodifications").style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    saveModificationFormCoaching() {
      this.saving_modification_form = true;
      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .collection("coaching")
        .doc("form")
        .set(this.formCoaching, { merge: true })
        .then(() => {
          this.saving_modification_form = false;
          document.getElementById(
            "savemodificationsformcoaching"
          ).style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    async sendNewNotification() {
      var titre = document.getElementById("titreNewNotification").value;
      var message = document.getElementById("textNewNotification").value;
      var idUser = this.idUser;

      var notification =
        '{"alert":{"title":"' + titre + '","text":"' + message + '"}}';

      const requestOptions = {
        method: "POST",
      };

      fetch(
        "https://management-api.wonderpush.com/v1/deliveries?accessToken=ZTg4ZGRjMjMyYWE2Mzg1MDUyOTA0ZmVkYTlmNmU1YWE0ZWIyYjVmYjA5NmNhNWNhZDJiMTMyZDcyYzU0ODFmMw&targetUserIds=" +
          idUser +
          "&notification=" +
          notification,
        requestOptions
      )
        .then((response) => response.json())
        .then(() => this.hideModalNewNotification());
    },
    async sendNewTicket() {
      var db = firebase.firestore();

      this.sending_ticket = true;

      var message = document.getElementById("textNewTicket").value;
      var referenceuser = db.collection("users").doc(this.idUser);

      //var coachId = firebase.auth().currentUser.uid;
      var coachId = this.user.coach;
      var referencecoach = db.collection("users").doc(coachId);

      var coachWriterId = firebase.auth().currentUser.uid;
      var referencecoachWrite = db.collection("users").doc(coachWriterId);

      var dateNewTicket = new Date().getTime();

      var newTicket = {
        status: JSON.parse(this.isAdmin) ? true : false,
        user: referenceuser,
        coach: referencecoach,
        date_start: dateNewTicket,
        first_author: referencecoachWrite,
        first_message: message,
      };

      await db
        .collection("premiumtickets")
        .add(newTicket)
        .then(async (docRef) => {
          var newMessage = {
            date: dateNewTicket,
            status: true,
            message: message,
            photo: [],
            author: referencecoachWrite,
          };
          await firebase
            .firestore()
            .collection("premiumtickets")
            .doc(docRef.id)
            .collection("messages")
            .add(newMessage);
        });

      //On envoi une notification
      var notification =
        '{"alert":{"title":"Nuovo messaggio privato","text":"' +
        message +
        '"}}';

      const requestOptions = {
        method: "POST",
      };

      fetch(
        "https://management-api.wonderpush.com/v1/deliveries?accessToken=ZTg4ZGRjMjMyYWE2Mzg1MDUyOTA0ZmVkYTlmNmU1YWE0ZWIyYjVmYjA5NmNhNWNhZDJiMTMyZDcyYzU0ODFmMw&targetUserIds=" +
          this.idUser +
          "&notification=" +
          notification,
        requestOptions
      );

      //Force la recharge du composant en changeant sa key
      this.forceReloadChat++;
      this.sending_ticket = false;
      this.hideModalNewTicket();
    },
    hideModalNewEvolution() {
      this.$refs["add-evolution"].hide();
      this.dateNewEvolution = null;
      this.poidsNewEvolution = null;
      this.photosNewEvolution = [];
    },
    showModalNewTicket() {
      this.$refs["modal-newTicket"].show();
    },
    showModalNewPayment() {
      this.$refs["modal-newPayment"].show();
    },
    showModalNewNotification() {
      this.$refs["modal-newNotification"].show();
    },
    showModalBpm(data) {
      this.seriesChartBpm[0].data = [];
      this.slowzone = 0;
      this.brulegraisse = 0;
      this.conditionphysique = 0;
      this.seriesChartBpm[0].data = data;

      data.forEach((bpm) => {
        if (bpm < this.fcmax * 0.60) this.slowzone++;
        else if (bpm < this.fcmax * 0.80) this.brulegraisse++;
        else this.conditionphysique++;
      });

      this.slowzonepourcentage = (this.slowzone * 100) / data.length;
      this.brulegraissepourcentage = (this.brulegraisse * 100) / data.length;
      this.conditionphysiquepourcentage =
        (this.conditionphysique * 100) / data.length;

      this.$refs["modal-bpm"].show();
      //Simuler un resize de la page pour afficher le graphique
      setTimeout(() => window.dispatchEvent(new Event("resize")), 500);
    },
    hideModalBpm() {
      this.$refs["modal-bpm"].hide();
    },
    hideModalNewNotification() {
      this.$refs["modal-newNotification"].hide();
    },
    hideModalNewTicket() {
      this.$refs["modal-newTicket"].hide();
      document.getElementById("textNewTicket").value = null;
    },
    showModalNewEvolution() {
      this.$refs["add-evolution"].show();
    },
    functionSelectEvolutionDelete(evolution, index) {
      this.selectEvolutionDelete = { evolution: evolution, index: index };
      this.showModal();
    },
    saveModifEvolution(index) {
      var newPoids = document.getElementById(
        "valueevolutionpoids" + index
      ).value;
      this.saving_modif_evolution = true;
      var db = firebase.firestore();
      db.collection("evolutions")
        .doc(this.evolutions[index].id)
        .update({ poids: parseFloat(newPoids) })
        .then(async () => {
          //On recalcul ensuite les données weights (1er poids, 1er date poids, besoin kcal, poids actuel et on recharge la page)
          var restructureWeights = firebase
            .functions()
            .httpsCallable("restructureWeight");

          await restructureWeights({ idUser: this.idUser }).then(() => {
            this.reRender();
          });
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    deleteEvolution(idEvolution) {
      this.deleting_evolution = true;
      var db = firebase.firestore();
      db.collection("evolutions")
        .doc(idEvolution)
        .delete()
        .then(() => {
          //On recalcul ensuite les données weights (1er poids, 1er date poids, besoin kcal, poids actuel et on recharge la page)
          console.log("on appelle la function restructure");
          var restructureWeights = firebase
            .functions()
            .httpsCallable("restructureWeight");
          restructureWeights({ idUser: this.idUser }).then(() => {
            console.log("fin de la fonction restructure");
            this.reRender();
          });
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    getLastPhoto() {
      var first = null;
      this.evolutions.forEach((evolution) => {
        if (evolution.data.photo != null && first == null)
          first = evolution.data;
      });
      return first;
    },
    getFirstPhoto() {
      return this.evolutions[this.evolutions.length - 1].data;
    },
    addNewEvolutions() {
      if (
        this.poidsNewEvolution != null &&
        this.dateNewEvolution != null &&
        this.photosNewEvolution.length > 0
      ) {
        this.adding_new_evolutions = true;
        this.photosNewEvolution.forEach((photo, index) => {
          //Pour chaque photo, on transfère et optimise avec transloadit
          var type = photo.name.split(".").pop();
          var filename =
            "evolutions/" +
            this.idUser +
            "-" +
            index +
            "-" +
            Date.now() +
            "." +
            type;

          const storageRef = firebase
            .app()
            .storage("gs://gabriellavicofitness")
            .ref(filename)
            .put(photo);
          storageRef.on(
            `state_changed`,
            (snapshot) => {
              this.uploadValue =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
              console.log(error.message);
            },
            () => {
              this.uploadValue = 100;
              storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
                console.log("upload fini: " + url);

                //on optimise la photo avec transloadit
                var data_photo = {
                  id: this.idUser,
                  url: url,
                };

                var transloadPhotoEvolution = firebase
                  .functions()
                  .httpsCallable("transloadPhotoEvolution");

                await transloadPhotoEvolution(data_photo).then(
                  async (result) => {
                    console.log("transload finish: " + result.data);

                    var db = firebase.firestore();

                    var referenceuser = db.collection("users").doc(this.idUser);

                    var newEvolution = {
                      date: this.dateNewEvolution,
                      photo: result.data,
                      poids: parseFloat(this.poidsNewEvolution),
                      pourcentage_eau: null,
                      pourcentage_graisse: null,
                      pourcentage_muscle: null,
                      tour_bras: null,
                      tour_cuisse: null,
                      tour_nombril: null,
                      user: referenceuser,
                    };

                    //On enregistre la nouvelle evolution
                    db.collection("evolutions")
                      .doc()
                      .set(newEvolution)
                      .then(async () => {
                        //On recalcul ensuite les données weights (1er poids, 1er date poids, besoin kcal, poids actuel et on recharge la page)
                        var restructureWeights = firebase
                          .functions()
                          .httpsCallable("restructureWeight");

                        await restructureWeights({ idUser: this.idUser }).then(
                          () => {
                            this.reRender();
                          }
                        );
                        /*
                    this.evolutions = [];
                    this.getEvolutions();

                    this.hideModalNewEvolution();
                    this.poidsNewEvolution = null;
                    this.dateNewEvolution = null;
                    this.photosNewEvolution = [];*/
                      });
                  }
                );
              });
            }
          );
        });
      }
      if (
        this.poidsNewEvolution != null &&
        this.dateNewEvolution != null &&
        this.photosNewEvolution.length == 0
      ) {
        this.adding_new_evolutions = true;

        var db = firebase.firestore();

        var referenceuser = db.collection("users").doc(this.idUser);

        var newEvolution = {
          date: this.dateNewEvolution,
          photo: null,
          poids: parseFloat(this.poidsNewEvolution),
          pourcentage_eau: null,
          pourcentage_graisse: null,
          pourcentage_muscle: null,
          tour_bras: null,
          tour_cuisse: null,
          tour_nombril: null,
          user: referenceuser,
        };

        //On enregistre la nouvelle evolution
        db.collection("evolutions")
          .doc()
          .set(newEvolution)
          .then(async () => {
            //On recalcul ensuite les données weights (1er poids, 1er date poids, besoin kcal, poids actuel et on recharge la page)
            var restructureWeights = firebase
              .functions()
              .httpsCallable("restructureWeight");

            await restructureWeights({ idUser: this.idUser }).then(() => {
              this.reRender();
            });
          });
      }
    },
    async getOptionsCoach() {
      var db = firebase.firestore();
      await db
        .collection("users")
        .where("role", "array-contains", "coach")
        .get()
        .then((coachs) => {
          coachs.forEach((coach) => {
            this.optionsCoach.push({
              value: coach.id,
              text: coach.data().first_name + " " + coach.data().last_name,
            });
          });
        });
      console.log("coach: " + JSON.stringify(this.optionsCoach));
    },
    updateSeriesWeightChart(weights, min, max) {
      this.seriesChartWeights[0].data = weights;
      this.optionsChartWeights.yaxis.min = parseInt(min);
      this.optionsChartWeights.yaxis.max = parseInt(max);
      this.optionsChartWeights.annotations.yaxis[0].y = this.objectif;
    },

    getMacros() {
      var proteing = Math.round((this.user.besoin_kcal * 0.25) / 4);
      var proteinkcal = Math.round(this.user.besoin_kcal * 0.25);

      var glucideg = Math.round((this.user.besoin_kcal * 0.45) / 4);
      var glucidekcal = Math.round(this.user.besoin_kcal * 0.45);

      var lipideg = Math.round((this.user.besoin_kcal * 0.3) / 9);
      var lipidekcal = Math.round(this.user.besoin_kcal * 0.3);

      return {
        proteing: proteing,
        proteinkcal: proteinkcal,
        glucideg: glucideg,
        glucidekcal: glucidekcal,
        lipideg: lipideg,
        lipidekcal: lipidekcal,
      };
    },
    showUpdateButton() {
      this.ajust_nutrition_modified = true;
    },
    cancelModifAjust() {
      this.ajust_nutrition =
        this.user.acf.ajust_nutrition == "" ? 0 : this.user.acf.ajust_nutrition;
      this.ajust_nutrition_modified = false;
    },
    besoinJournalierKcal() {
      var besoin_kcal = this.user.besoin_kcal;

      if (Math.sign(this.user.ajust_nutrition) == "1")
        besoin_kcal =
          Math.round(besoin_kcal) +
          Math.round(besoin_kcal) * (Math.abs(this.user.ajust_nutrition) / 100);

      if (Math.sign(this.user.ajust_nutrition) == "-1")
        besoin_kcal =
          Math.round(besoin_kcal) -
          Math.round(besoin_kcal) * (Math.abs(this.user.ajust_nutrition) / 100);

      this.user.besoin_kcal = Math.round(besoin_kcal);
    },
    getTotalWorkouts() {
      var totalTime = 0;
      var totalKcal = 0;
      this.workouts.forEach((workout) => {
        if (workout.data.duree_workout != null)
          totalTime += parseInt(workout.data.duree_workout);
        if (workout.data.kcal != null) totalKcal += parseInt(workout.data.kcal);
      });

      return {
        time: this.hhmmss(totalTime),
        kcal: totalKcal,
      };
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },
    sortedArray(paiements) {
      return paiements.sort((a, b) => a.timestamp - b.timestamp);
    },
    getTotalPay() {
      var total = 0;
      this.paiements.forEach((pay) => {
        total += pay.amount_paid;
      });
      return total / 100;
    },
    paypalToArray(data, namePaypal) {
      var nbTransaction = data.split("L_TIMESTAMP").length - 1;
      const toArray = data
        .split("&")
        .map((p) => p.split("="))
        .reduce((obj, pair) => {
          const [key, value] = pair.map(decodeURIComponent);
          obj[key] = value;
          return obj;
        }, {});
      for (var i = 0; i < nbTransaction; i++) {
        //On transforme la date en timestamp
        var dateFull = toArray["L_TIMESTAMP" + i];
        var dateFullSplit = dateFull.split("T");
        var date = dateFullSplit[0];
        var spliteDate = date.split("-");
        var annee = spliteDate[0];
        var mois = spliteDate[1];
        var jour = spliteDate[2];
        var ts = new Date(Date.UTC(annee, mois - 1, jour)) / 1000;

        var transaction = {
          timestamp: ts,
          amount_due: toArray["L_AMT" + i] * 100,
          amount_paid: toArray["L_AMT" + i] * 100,
          status:
            toArray["L_STATUS" + i] == "Completed"
              ? "succeeded"
              : toArray["L_STATUS" + i],
          methode: namePaypal,
        };
        this.paiements.push(transaction);
        this.getPaymentsOrder();
      }
    },
    getArrondi(value) {
      return Math.round(value * 10) / 10;
      //return parseFloat(value).toFixed(0);
    },
    getMinutes(secondes) {
      return Math.floor(secondes / 60);
    },
    secondesToTime(secondes) {
      return new Date(secondes * 1000).toISOString().substr(11, 8);
    },   
    getUser() {
      console.log("on check user");
      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .onSnapshot((user) => {
          console.log("user:" + user.data());
          this.user = user.data();
          //On lance ensuite la recherche de paiement
          this.getAllPayments();
          this.getFcmax();
        });
    },
    getFcmax() {
      var age = this.getAge();
      this.fcmax = 220 - age;
      this.optionsChartBpm.yaxis.max = this.fcmax + 10;
      this.optionsChartBpm.annotations.yaxis[0].y = this.fcmax;
      this.optionsChartBpm.annotations.yaxis[0].label.text =
        "FCMAX (" + this.fcmax + "bpm)";
      this.optionsChartBpm.annotations.yaxis[1].y = this.fcmax * 0.6;
      this.optionsChartBpm.annotations.yaxis[1].label.text =
        "Brûle-graisse (" + this.fcmax * 0.6 + "bpm)";
      this.optionsChartBpm.annotations.yaxis[2].y = this.fcmax * 0.8;
      this.optionsChartBpm.annotations.yaxis[2].label.text =
        "Brûle-graisse + condition physique (" + this.fcmax * 0.8 + "bpm)";
    },
    getProgrammesCoaching() {
      var db = firebase.firestore();
      var referenceuser = db.collection("users").doc(this.idUser);

      db.collection("premiumprograms")
        .where("user", "==", referenceuser)
        .orderBy("range.end", "desc")
        .onSnapshot((querySnapshot) => {
          this.programmes = [];
          querySnapshot.forEach((doc) => {
            this.programmes.push({
              id: doc.id,
              data: doc.data(),
            });
          });
        });
    },
    async getHistoricWorkouts() {
      var db = firebase.firestore();
      var referenceuser = db.collection("users").doc(this.idUser);

      await db
        .collection("historicworkouts")
        .where("user", "==", referenceuser)
        .orderBy("date", "desc")
        .onSnapshot((querySnapshot) => {
          this.workouts = [];
          querySnapshot.forEach((doc) => {
            this.workouts.push({
              id: doc.id,
              data: doc.data(),
            });
          });
        });
    },
    getFormCoaching() {
      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .collection("coaching")
        .doc("form")
        .get()
        .then((form) => {
          if (form.exists) this.formCoaching = form.data();
          else
            this.formCoaching = {
              allergie: null,
              a_propos: null,
              journee_type: null,
              temps_entrainer: null,
              medicaments: null,
              probleme_hormonal: null,
              eau_jour: null,
              problemes_physique: null,
              materiel_entrainement: null,
              passe_sportif: null,
              adresse_complete: null,
              telephone: null,
            };
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a.data[prop] > b.data[prop]) {
          return 1;
        } else if (a.data[prop] < b.data[prop]) {
          return -1;
        }
        return 0;
      };
    },
    async getEvolutions() {
      var db = firebase.firestore();

      var referenceuser = db.collection("users").doc(this.idUser);
      db.collection("evolutions")
        .where("user", "==", referenceuser)
        .orderBy("date", "desc")
        .onSnapshot((querySnapshot) => {
          this.evolutions = [];
          console.log("evolutions: " + JSON.stringify(this.evolutions));
          querySnapshot.forEach((doc) => {
            this.evolutions.push({
              id: doc.id,
              data: doc.data(),
            });
          });
        });

      this.regenerateChartEvolution();
    },
    regenerateChartEvolution() {
      var weightsTab = [];
      var dateWeights = [];

      this.evolutions.sort(this.GetSortOrder("date"));

      this.evolutions.forEach((evolution) => {
        if (!dateWeights.includes(evolution.data.date)) {
          var date = new Date(evolution.data.date);
          weightsTab.push({
            x:
              date.getDate() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getFullYear(),
            y: evolution.data.poids,
          });
          dateWeights.push(evolution.data.date);
        }
      });

      var min = 1000;
      var max = 0;

      this.evolutions.forEach((evolution) => {
        if (evolution.data.poids < min) min = evolution.data.poids;
        if (evolution.data.poids > max) max = evolution.data.poids;
      });

      if (min > this.user.target) min = this.user.target - 2;
      else min = parseInt(min) - 2;
      if (this.user.target > max) max = this.user.target + 2;
      else max = parseInt(max) + 2;

      console.log("weights: " + JSON.stringify(weightsTab));

      this.updateSeriesWeightChart(weightsTab, min, max);
    },
    async regenerateMenu() {
      this.regenerating_menu = true;
      var regenerateMenu = firebase.functions().httpsCallable("regenerateMenu");

      await regenerateMenu({
        idUser: this.idUser,
        regime: this.user.regime,
      }).then(() => {
        this.regenerating_menu = false;
      });
    },
    getAllPayments() {
      //On lance les function de payment
      this.getPaymentsApp();
      this.getPaymentsVirement();
      this.getPaymentsPaypal(
        this.username_paypal1,
        this.pass_paypal1,
        this.api_signature1,
        "Paypal (contact@vicodellaforma.com)"
      );
      this.getPaymentsPaypal(
        this.username_paypal2,
        this.pass_paypal2,
        this.api_signature2,
        "Paypal (contact@gabriellavico.com)"
      );
      this.getPaymentsStripe(
        this.api_stripe1,
        "Stripe (contact@proteineandco.fr)"
      );
      this.getPaymentsStripe(
        this.api_stripe2,
        "Stripe (contact@vicodellaforma.com)"
      );
      this.getPaymentsStripe(
        this.api_stripe3,
        "Stripe (contact@gabriellavico.com)"
      );
      this.getPaymentsStripe(
        this.api_stripe4,
        "Stripe Thrivecart (contact@gabriellavico.com)"
      );
      this.getPaymentsStripe(
        this.api_stripe5,
        "Stripe Snella (contact@gabriellavico.com)"
      );
    },
    getPayments() {
      const paiementsSrt = this.paiements.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      return paiementsSrt;
    },
    async getPaymentsVirement() {
      console.log("get virements");
      var db = firebase.firestore();
      await db
        .collection("users")
        .doc(this.idUser)
        .collection("paiements")
        .get()
        .then((virements) => {
          console.log("virements: " + JSON.stringify(virements));
          virements.forEach((virement) => {
            var dateSplit = virement.data().date.split("-");
            var ts =
              new Date(Date.UTC(dateSplit[0], dateSplit[1] - 1, dateSplit[2])) /
              1000;

            var transaction = {
              timestamp: ts,
              amount_due: virement.data().montant * 100,
              amount_paid: virement.data().montant * 100,
              status: "succeeded",
              methode: "Virement",
              id: virement.id,
            };
            this.paiements.push(transaction);
            this.getPaymentsOrder();
          });
        });
    },

    async getPaymentsPaypal(username, pass, signature, namePaypal) {
      var email_paypal = this.user.mail_paypal
        ? this.user.mail_paypal
        : this.user.email;

      const response = await fetch(
        "https://api-3t.paypal.com/nvp?USER=" +
          username +
          "&PWD=" +
          pass +
          "&SIGNATURE=" +
          signature +
          "&STARTDATE=2013-08-24T05:38:48Z&METHOD=TransactionSearch&VERSION=78&EMAIL=" +
          email_paypal
      );
      const data = await response.text();
      this.paypalToArray(data, namePaypal);
    },

    async getPaymentsStripe(api_key, nameStripe) {
      var email_stripe = this.user.mail_stripe
        ? this.user.mail_stripe
        : this.user.email;

      var customer_ids = [];

      await axios
        .get("https://api.stripe.com/v1/customers?email=" + email_stripe, {
          headers: {
            Authorization: `Bearer ${api_key}`,
          },
        })
        .then((res) => {
          res.data.data.forEach((element) => {
            customer_ids.push(element.id);
          });
        });

      //Maintenant qu'on a recupérer les id du client sur stripe, on va lister toutes les factures correspondant à ces client ID

      customer_ids.forEach((customer_id) => {
        axios
          .get("https://api.stripe.com/v1/charges?customer=" + customer_id, {
            headers: {
              Authorization: `Bearer ${api_key}`,
            },
          })
          .then((res) => {
            res.data.data.forEach((payment) => {
              var transaction = {
                timestamp: payment.created,
                amount_due: payment.amount,
                amount_paid: payment.amount_captured,
                status: payment.status,
                methode: nameStripe,
              };
              this.paiements.push(transaction);
              this.getPaymentsOrder();
            });
          });
      });
    },
    async addNewPayment() {
      var dateNewPayment = document.getElementById("dateNewPayment").value;
      var amountNewPayment = document.getElementById("amountNewPayment").value;

      console.log(dateNewPayment + amountNewPayment);

      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .collection("paiements")
        .add({ date: dateNewPayment, montant: amountNewPayment })
        .then(() => {
          this.$refs["modal-newPayment"].hide();
          this.paiements = [];
          this.getAllPayments();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    //Impossible pour le moment de récupérer les paiements depuis revenuecat
    async getPaymentsApp() {
      /*var userId = this.user.data.ID;

      await axios
        .get("https://api.revenuecat.com/v1/subscribers/" + userId, {
          headers: {
            Authorization: "Bearer sk_GowZapsGFPLRpWGQjmgPvLbmAJgmd",
          },
        })
        .then((res) => {
          this.appPayments = res.data;
        });*/
    },
    getAge() {
      var today = new Date();
      var splitBirth = this.user.date_naissance.split("-");
      var birthDate = new Date(this.user.date_naissance);
      var age = today.getFullYear() - splitBirth[0];
      var m = today.getMonth() - splitBirth[1];
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toMonthBefore(nbmonth) {
      var d = new Date();
      return new Date(d.setMonth(d.getMonth() - nbmonth));
    },
    getPaymentsOrder() {
      this.paiements = this.paiements.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp); // ascending
      });
    },
    unpublishprogram(idProgram) {
      var db = firebase.firestore();
      db.collection("premiumprograms").doc(idProgram).update({ status: false });
    },
    publishprogram(idProgram) {
      var db = firebase.firestore();
      db.collection("premiumprograms").doc(idProgram).update({ status: true });
    },
    dateFromTimestamp(timestamp) {
      var date = new Date(timestamp);
      return this.transformDate(date.toISOString().substr(0, 10));
    },
    deleteProgramPremium(idProgram) {
      var db = firebase.firestore();
      db.collection("premiumprograms").doc(idProgram).delete();
    },
    deleteWorkoutHistoric(idHistoric) {
      var db = firebase.firestore();
      db.collection("historicworkouts").doc(idHistoric).delete();
    },
    deleteVirement(idPaiement) {
      console.log(idPaiement);
      var db = firebase.firestore();
      db.collection("users")
        .doc(this.idUser)
        .collection("paiements")
        .doc(idPaiement)
        .delete();
      this.paiements = [];
      this.getAllPayments();
    },
    setTimestampDateNextProgram() {
      var date = document.getElementById("dateNextProgram").value;
      if (date == null || date == "") this.user.next_program_premium = null;
      else {
        var timestamp = new Date(date).getTime();
        this.user.next_program_premium = timestamp;
      }
    },
    setTimestampDateEndPremium() {
      var date = document.getElementById("datePremium").value;
      if (date == null || date == "") this.user.end_subscription_premium = null;
      else {
        var timestamp = new Date(date).getTime();
        this.user.end_subscription_premium = timestamp;
      }
    },
    setTimestampDateEndBasic() {
      var date = document.getElementById("dateBasic").value;
      if (date == null || date == "") this.user.end_subscription_basic = null;
      else {
        var timestamp = new Date(date).getTime();
        this.user.end_subscription_basic = timestamp;
      }
    },
    timestampToDate(timestamp) {
      return timestamp != null
        ? new Date(timestamp).toISOString().substr(0, 10)
        : null;
    },
    timestampToDate2(timestamp) {
      return timestamp != null && !isNaN(this.user.next_program_premium)
        ? new Date(timestamp).toISOString().substr(0, 10)
        : null;
    },
    transformDate(dateUs) {
      var splitDate = dateUs.split("-");
      return splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal ref="modal-bpm" size="lg" hide-footer title="BPM">
      <div class="d-block text-center">
        <div class="container">
          <div class="row">
            <div class="col" v-if="this.fcmax != null">
              <apexchart
                width="100%"
                height="500px"
                :options="optionsChartBpm"
                :series="seriesChartBpm"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-1">
            <div class="col-4 alert alert-secondary">
              <h6>SLOWZONE</h6>
              {{ secondesToTime(this.slowzone) }}<br />
              <b>{{ this.slowzonepourcentage }}%</b>
            </div>
            <div class="col-4 alert alert-warning">
              <h6>BRULE-GRAISSE</h6>
              {{ secondesToTime(this.brulegraisse) }}<br />
              <b>{{ this.brulegraissepourcentage }}%</b>
            </div>
            <div class="col-4 alert alert-danger">
              <h6>CONDITION PHYS.</h6>
              {{ secondesToTime(this.conditionphysique) }}<br />
              <b>{{ this.conditionphysiquepourcentage }}%</b>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="modal-newTicket" size="lg" hide-footer title="New Ticket">
      <div class="d-block text-center">
        <div class="container">
          <div class="row">
            <div class="col">
              <b-form-textarea
                id="textNewTicket"
                placeholder="Message..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div class="d-grid gap-2">
                <button
                  class="btn btn-primary mt-2"
                  type="button"
                  @click="sendNewTicket()"
                >
                  <span
                    v-if="sending_ticket"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Send new Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modal-newNotification"
      size="lg"
      hide-footer
      title="New Push Notification"
    >
      <div class="d-block text-center">
        <div class="container">
          <div class="row">
            <div class="col">
              <p>
                <label>Title (max 35 characters)</label>
                <b-form-input
                  id="titreNewNotification"
                  maxlength="35"
                  placeholder="Titre"
                ></b-form-input>
              </p>
              <p>
                <label>Title (max 120 characters)</label>
                <b-form-textarea
                  id="textNewNotification"
                  maxlength="120"
                  placeholder="Message..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </p>
              <div class="d-grid gap-2">
                <button
                  class="btn btn-primary mt-2"
                  type="button"
                  @click="sendNewNotification()"
                >
                  <span
                    v-if="sending_ticket"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Send Push Notification
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="modal-newPayment" size="lg" hide-footer title="New Payment">
      <div class="d-block text-center">
        <div class="container">
          <div class="row">
            <div class="col">
              <p>
                <b-form-input id="dateNewPayment" type="date"></b-form-input>
              </p>
              <p>
                <b-form-input
                  id="amountNewPayment"
                  placeholder="Amount (€€€)"
                  type="number"
                ></b-form-input>
              </p>
              <div class="d-grid gap-2">
                <button
                  class="btn btn-primary mt-2"
                  type="button"
                  @click="addNewPayment()"
                >
                  <span
                    v-if="sending_ticket"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Add New Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="add-evolution"
      size="lg"
      hide-footer
      title="Add new evolution"
    >
      <div class="d-block text-center">
        <div class="container">
          <div class="row">
            <div class="col-auto">
              <DatePicker
                v-model="dateNewEvolution"
                :model-config="modelConfig"
              />
            </div>
            <div class="col">
              <b-form-input
                v-model="poidsNewEvolution"
                placeholder="Weight (kg)"
              ></b-form-input>
              <br />
              <b-form-file
                v-model="photosNewEvolution"
                :state="Boolean(photosNewEvolution)"
                multiple
                placeholder="Select photos..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div class="d-grid gap-2 mt-3">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="addNewEvolutions()"
                >
                  <span
                    v-if="adding_new_evolutions"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span
                  >Add new evolution(s)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div v-if="user == null" class="d-flex justify-content-center mw-100">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="row align-items-start mb-5" v-if="user != null">
      <div class="col-2">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col">
              <div v-if="user.photo_profil">
                <img
                  :src="user.photo_profil"
                  alt
                  class="img-fluid"
                  style="width: 100%"
                />
              </div>
              <div v-if="!user.photo_profil">
                <img
                  src="@/assets/images/users/avatar-inconnu.jpeg"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="row" id="savemodifications" style="display: none">
            <div class="col">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-success btn-large mt-2"
                  @click="saveModificationUser()"
                  type="button"
                >
                  <span
                    v-if="saving_modification"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Save Modifications
                </button>
              </div>
            </div>
          </div>
          <div
            class="row"
            id="savemodificationsformcoaching"
            style="display: none"
          >
            <div class="col">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-success btn-large mt-2"
                  @click="saveModificationFormCoaching()"
                  type="button"
                >
                  <span
                    v-if="saving_modification_form"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Save Modifications Form
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mt-3">
              <div class="text-center">
                <span
                  v-for="(role, index) in user.role"
                  :key="'listrole-' + index"
                  class="badge bg-warning text-dark"
                  >{{ role }}</span
                >
              </div>
              <h5 class="mt-1 mb-1 text-center">
                {{ user.first_name }} {{ user.last_name }}
              </h5>
              <div class="text-center">
                <i>{{ user.email }}</i>
              </div>
              <div class="text-center mt-3">
                <i
                  class="fas fa-comment-dots fa-2x me-2"
                  style="cursor: pointer"
                  @click="showModalNewTicket()"
                ></i>
                <i
                  class="fas fa-bell fa-2x me-2"
                  style="cursor: pointer"
                  @click="showModalNewNotification()"
                ></i>
              </div>
            </div>
          </div>

          <hr class="my-4" />
          <div class="alert alert-warning" v-if="JSON.parse(isAdmin)">
            <b-form-group label="Roles:">
              <b-form-checkbox-group
                v-model="user.role"
                :options="optionsRole"
                name="role"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>

          <div class="mb-3" v-if="JSON.parse(isAdmin)">
            <label for="selectedCoach" class="form-label">Coach Tickets</label>
            <form class="row g-3">
              <div class="col">
                <b-form-select
                  id="selectedCoach"
                  v-model="user.coach"
                  :options="optionsCoach"
                  size="sm"
                  class="mb-2"
                  style="width: 100%"
                ></b-form-select>
              </div>
            </form>
          </div>

          <div class="mb-3" v-if="JSON.parse(isAdmin)">
            <label for="selectedCoach" class="form-label">Coach Programs</label>
            <form class="row g-3">
              <div class="col">
                <b-form-select
                  id="selectedCoach"
                  v-model="user.coach_programs"
                  :options="optionsCoach"
                  size="sm"
                  class="mb-2"
                  style="width: 100%"
                ></b-form-select>
              </div>
            </form>
          </div>

          <div class="mb-3" v-if="JSON.parse(isAdmin)">
            <label for="enddate" class="form-label">End Date Premium</label>
            <form class="row g-3">
              <div class="col">
                <input
                  type="date"
                  class="form-control"
                  id="datePremium"
                  :value="timestampToDate(user.end_subscription_premium)"
                  @change="setTimestampDateEndPremium()"
                />
              </div>
            </form>
          </div>
          <div class="mb-3" v-if="JSON.parse(isAdmin)">
            <label for="enddate" class="form-label">End Date Basic</label>
            <form class="row g-3">
              <div class="col">
                <input
                  type="date"
                  class="form-control"
                  id="dateBasic"
                  :value="timestampToDate(user.end_subscription_basic)"
                  @change="setTimestampDateEndBasic()"
                />
              </div>
            </form>
          </div>

          <div class="mb-3">
            <label for="notes_coach" class="form-label">Notes</label>
            <textarea
              class="form-control mb-2"
              id="notes_coach"
              rows="8"
              v-model="user.note_user_coach"
              style="font-size: 12px"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="col-10">
        <div
          class="alert alert-danger"
          role="alert"
          @click="showModalNewEvolution()"
          style="cursor: pointer"
          v-if="evolutions.length == 0"
        >
          Attention! Please <strong>add a New Evolution</strong> now.
        </div>
        <div class="card mb-0">
          <b-tabs content-class="p-4" justified class="nav-tabs-custom">
            <b-tab active>
              <template v-slot:title>
                <div>
                  <i class="uil uil-analytics font-size-16"></i>
                  <span class="d-none d-sm-block">Stats</span>
                </div>
              </template>
              <div class="min-vh-100">
                <div class="container-fluid">
                  <div class="row mb-5">
                    <h2>Weight Statistics</h2>
                    <div class="col-4">
                      <div class="row">
                        <div class="col-6">
                          <div
                            class="alert text-center alert-secondary"
                            role="alert"
                          >
                            <i class="fas fa-bullseye fa-3x"></i>
                            <h5 class="fw-bold">Objectif</h5>
                            <span class="fs-3 fw-bold"
                              >{{ user.target }}<small>kg</small></span
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <div
                            class="alert text-center"
                            role="alert"
                            :class="
                              getArrondi(
                                user.poids_actuel - user.first_weight
                              ) < 0
                                ? 'alert-success'
                                : getArrondi(
                                    user.poids_actuel - user.first_weight
                                  ) > 0
                                ? 'alert-danger'
                                : 'alert-secondary'
                            "
                          >
                            <i class="fas fa-chart-line fa-3x"></i>
                            <h5 class="fw-bold">Evolution</h5>
                            <span class="fs-3 fw-bold"
                              >{{
                                getArrondi(
                                  user.poids_actuel - user.first_weight
                                )
                              }}<small>kg</small></span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <div
                            class="alert alert-info text-center mb-0"
                            role="alert"
                          >
                            <h6>Start</h6>
                            <span class="fs-5"
                              >{{ user.first_weight }}<small>kg</small></span
                            >
                          </div>
                        </div>
                        <div class="col-4">
                          <div
                            class="alert alert-info text-center mb-0"
                            role="alert"
                          >
                            <h6>Actual</h6>
                            <span class="fs-5"
                              >{{ user.poids_actuel }}<small>kg</small></span
                            >
                          </div>
                        </div>
                        <div class="col-4">
                          <div
                            class="alert alert-info text-center mb-0"
                            role="alert"
                          >
                            <h6>Target</h6>
                            <span class="fs-5"
                              >{{ user.target }}<small>kg</small></span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div
                        class="
                          alert alert-secondary
                          text-center
                          h-100
                          d-inline-block
                          w-100
                        "
                        role="alert"
                      >
                        <apexchart
                          width="100%"
                          :options="optionsChartWeights"
                          :series="seriesChartWeights"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <h2>Others Statistics</h2>

                    <div class="col-4">
                      <div class="alert alert-success text-center" role="alert">
                        <h5><i class="fas fa-dumbbell"></i> Workouts</h5>
                        <p class="fs-1">
                          {{ workouts != null ? workouts.length : 0 }}
                        </p>
                        <hr />
                        <i class="fas fa-stopwatch"></i> Total Time:
                        {{ workouts != null ? getTotalWorkouts()["time"] : 0
                        }}<br />
                        <i class="fas fa-fire"></i> Total Kcal:
                        {{
                          workouts != null ? getTotalWorkouts()["kcal"] : 0
                        }}kcal<br />
                        <i class="fas fa-info-circle"></i> Last Workout:
                        {{ dateFromTimestamp(user.last_training) }}
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="alert alert-success text-center" role="alert">
                        <h5><i class="fas fa-dumbbell"></i> Tickets</h5>
                        <p class="fs-1">
                          {{ workouts != null ? workouts.length : 0 }}
                        </p>
                        <!--<hr />
                        <i class="fas fa-stopwatch"></i> Total Time:
                        {{ workouts != null ? getTotalWorkouts()["time"] : 0
                        }}<br />
                        <i class="fas fa-fire"></i> Total Kcal:
                        {{
                          workouts != null ? getTotalWorkouts()["kcal"] : 0
                        }}kcal<br />
                        <i class="fas fa-info-circle"></i> Last Workout:
                        {{ dateFromTimestamp(user.last_training) }}-->
                      </div>
                    </div>

                    <!-- <div class="col-4">
                      <div class="alert alert-success text-center" role="alert">
                        <h5><i class="fas fa-dumbbell"></i> Workouts</h5>
                        <p class="fs-1">
                          {{ workouts != null ? workouts.length : 0 }}
                        </p>
                        <hr />
                        <i class="fas fa-stopwatch"></i> Total Time:
                        {{ workouts != null ? getTotalWorkouts()["time"] : 0
                        }}<br />
                        <i class="fas fa-fire"></i> Total Kcal:
                        {{
                          workouts != null ? getTotalWorkouts()["kcal"] : 0
                        }}kcal<br />
                        <i class="fas fa-info-circle"></i> Last Workout:
                        {{
                          workouts != null && workouts.length > 0
                            ? workouts[0].dateworkout
                            : "/"
                        }}
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="uil uil-user-circle font-size-16"></i>
                <span class="d-none d-sm-block">Profil</span>
              </template>
              <div class="min-vh-100">
                <h3>Profil</h3>
                <table class="table">
                  <tbody>
                    <tr>
                      <th width="200px">ID</th>
                      <td>
                        {{ idUser }}
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Email</th>
                      <td>
                        <b-form-input
                          v-model="user.email"
                          placeholder="Email"
                          :disabled="JSON.parse(isAdmin) ? false : true"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Langue</th>
                      <td>
                        <b-form-select
                          v-model="user.locale"
                          :options="locale"
                          style="width: 100%"
                        ></b-form-select>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Sexe</th>
                      <td>
                        <b-form-select
                          v-model="user.sexe"
                          :options="sexe"
                          style="width: 100%"
                        ></b-form-select>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">First name</th>
                      <td>
                        <b-form-input
                          v-model="user.first_name"
                          placeholder="First name"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Last name</th>
                      <td>
                        <b-form-input
                          v-model="user.last_name"
                          placeholder="First name"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Naissance</th>
                      <td>
                        <b-form-input
                          v-model="user.date_naissance"
                          class="mb-2"
                          type="date"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Objectif (kg)</th>
                      <td>
                        <b-form-input
                          v-model="user.target"
                          type="number"
                          placeholder="Objectif"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Taille (cm)</th>
                      <td>
                        <b-form-input
                          v-model.number="user.taille"
                          type="number"
                          placeholder="Taille"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Materiel</th>
                      <td>
                        <div>
                          <b-form-group
                            ><b-form-checkbox-group
                              id="checkbox-materiel"
                              v-model="user.materiel"
                              :options="materiels"
                              name="materiel"
                            ></b-form-checkbox-group
                          ></b-form-group>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Niveau</th>
                      <td>
                        <b-form-select
                          v-model="user.niveau"
                          :options="niveaux"
                          style="width: 100%"
                        ></b-form-select>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Mail Stripe</th>
                      <td>
                        <b-form-input
                          v-model="user.mail_stripe"
                          placeholder="Mail Stripe"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Mail Paypal</th>
                      <td>
                        <b-form-input
                          v-model="user.mail_paypal"
                          placeholder="Mail Paypal"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">ID Montre</th>
                      <td>
                        <b-form-input
                          v-model="user.montre"
                          placeholder="ID montre"
                          type="number"
                        ></b-form-input>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Nutrition?</th>
                      <td>
                        <b-form-checkbox v-model="user.affiche_nutrition"
                          >Show Nutrition</b-form-checkbox
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <h3>Form Coaching</h3>
                <table class="table">
                  <tbody>
                    <tr>
                      <th width="200px">Allergie</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.allergie"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">A propos</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.a_propos"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Journee Type</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.journee_type"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Temps pour s'entrainer</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.temps_entrainer"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Medicament?</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.medicaments"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Problème hormonal?</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.probleme_hormonal"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Problème physique?</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.problemes_physique"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Eau/jour</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.eau_jour"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Materiel</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.materiel_entrainement"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Passe sportif</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.passe_sportif"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Adresse</th>
                      <td>
                        <b-form-textarea
                          v-model="formCoaching.adresse_complete"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th width="200px">Telephone</th>
                      <td>
                        <b-form-input
                          v-model="formCoaching.telephone"
                        ></b-form-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <i class="uil uil-chart-down font-size-16"></i>
                <span class="d-none d-sm-block">Evolution</span>
              </template>
              <div class="min-vh-100">
                <div>
                  <div
                    class="container"
                    v-if="
                      evolutions.length > 0 &&
                      getFirstPhoto() != null &&
                      getLastPhoto() != null
                    "
                  >
                    <div class="row">
                      <div class="col text-center">
                        <h6>First Photo</h6>
                        <p>
                          {{ new Date(getFirstPhoto().date).getDate() }}
                          -
                          {{ new Date(getFirstPhoto().date).getMonth() + 1 }}
                          -
                          {{ new Date(getFirstPhoto().date).getFullYear() }}
                          / {{ getFirstPhoto().poids }}kg
                        </p>
                        <img
                          :src="getFirstPhoto().photo"
                          class="rounded img-fluid"
                        />
                      </div>
                      <div class="col text-center">
                        <h6>Last Photo</h6>
                        <p>
                          {{ new Date(getLastPhoto().date).getDate() }} -
                          {{ new Date(getLastPhoto().date).getMonth() + 1 }}
                          -
                          {{ new Date(getLastPhoto().date).getFullYear() }}
                          / {{ getLastPhoto().poids }}kg
                        </p>
                        <img
                          :src="getLastPhoto().photo"
                          class="rounded img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" />
                  <div class="container mx-0 mb-3">
                    <div class="row">
                      <div class="col"><h6>All Evolutions</h6></div>
                      <div class="alert alert-warning" role="alert">
                        Double clic to delete an evolution.
                        <b>Please note, this action is irreversible!</b>
                      </div>
                      <div class="col">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm float-end"
                          @click="showModalNewEvolution()"
                        >
                          Add new evolution
                        </button>
                      </div>
                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="20px">#</th>
                        <th scope="col" width="200px">Photo</th>
                        <th scope="col">Date/Weight</th>
                        <th scope="col">Infos</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(evolution, index) in evolutions"
                        :key="'listweight-' + index"
                      >
                        <th scope="row">{{ index }}</th>
                        <td>
                          <img
                            :src="evolution.data.photo"
                            class="rounded img-fluid"
                            width="200px"
                          />
                        </td>
                        <td>
                          <strong
                            >{{ new Date(evolution.data.date).getDate() }}-{{
                              new Date(evolution.data.date).getMonth() + 1
                            }}-{{
                              new Date(evolution.data.date).getFullYear()
                            }}
                            :</strong
                          >
                          <br />
                          <input
                            type="text"
                            :id="'valueevolutionpoids' + index"
                            :value="evolution.data.poids"
                          />kg
                          <button
                            type="button"
                            :id="'buttonsaveevolutionpoids' + index"
                            class="btn btn-success btn-sm"
                            @click="saveModifEvolution(index)"
                          >
                            <span
                              v-if="saving_modif_evolution"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span
                            >Save
                          </button>
                        </td>
                        <td>
                          <h6>Note:</h6>
                          <i>{{ evolution.data.note }}</i>
                        </td>

                        <td v-if="JSON.parse(isAdmin)">
                          <button
                            type="button"
                            :disabled="evolutions.length > 1 ? false : true"
                            class="btn btn-danger btn-sm"
                            @dblclick="deleteEvolution(evolution.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div>
                  <i class="uil uil-file-copy-alt font-size-16"></i>
                  <span class="d-none d-sm-block">Programs</span>
                </div>
              </template>
              <div class="float-end btn btn-outline-success">
                <router-link
                  :to="{
                    name: 'Premiumprogramnew',
                    params: { iduser: idUser, idcoach: user.coach },
                  }"
                  target="_blank"
                >
                  + New program</router-link
                >
              </div>
              <div>
                <h2>All programms</h2>
                <div class="alert alert-warning mb-3" role="alert">
                  Double clic to delete a program.
                  <b>Please note, this action is irreversible!</b>
                </div>
                <div class="mb-3 p-2 alert-success" v-if="JSON.parse(isAdmin)">
                  <label for="nextprogram" class="form-label"
                    >Next date program</label
                  >
                  <form class="row g-3">
                    <div class="col">
                      <input
                        type="date"
                        class="form-control"
                        id="dateNextProgram"
                        :value="timestampToDate2(user.next_program_premium)"
                        @change="setTimestampDateNextProgram()"
                      />
                    </div>
                  </form>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">ID</th>
                      <th scope="col">Start</th>
                      <th scope="col">End</th>
                      <th scope="col">Status</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(programme, index) in programmes"
                      :key="'listprogrammes-' + index"
                    >
                      <th scope="row">{{ index }}</th>
                      <td>{{ programme.id }}</td>
                      <td>{{ transformDate(programme.data.range.start) }}</td>
                      <td>{{ transformDate(programme.data.range.end) }}</td>
                      <td>
                        <button
                          v-if="programme.data.status"
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          @click="
                            JSON.parse(isAdmin)
                              ? unpublishprogram(programme.id)
                              : null
                          "
                        >
                          <i class="fas fa-check"></i> Published
                        </button>
                        <button
                          v-if="!programme.data.status"
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          @click="
                            JSON.parse(isAdmin)
                              ? publishprogram(programme.id)
                              : null
                          "
                        >
                          <i class="fas fa-check"></i> Not Publish
                        </button>
                      </td>
                      <td>
                        <a
                          class="btn btn-primary"
                          :href="`/premiumprogram/edit/${programme.id}`"
                          role="button"
                          target="_blank"
                          ><i class="fas fa-edit"></i
                        ></a>
                      </td>
                      <td v-if="JSON.parse(isAdmin)">
                        <button
                          type="button"
                          class="btn btn-danger"
                          @dblclick="deleteProgramPremium(programme.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div>
                  <i class="uil uil-dumbbell font-size-16"></i>
                  <span class="d-none d-sm-block">Workouts</span>
                </div>
              </template>
              <div>
                <div class="my-3">
                  <h2>Workouts</h2>
                  <div class="alert alert-warning" role="alert">
                    Double clic to delete a workout.
                    <b>Please note, this action is irreversible!</b>
                  </div>
                  <table class="table mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Time</th>
                        <th scope="col">Kcal</th>
                        <th scope="col">Commentaire</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(workout, index) in workouts"
                        :key="'workoutlist-' + index"
                        :class="
                          workout.data.status ? 'table-success' : 'table-danger'
                        "
                      >
                        <th scope="row" class="align-middle">
                          <img
                            v-if="
                              workout.data.status &&
                              workout.data.sensation >= 1 &&
                              workout.data.sensation < 6
                            "
                            :src="
                              require(`@/assets/images/smiley/sensation${workout.data.sensation}.png`)
                            "
                            width="30px"
                          />
                          <div
                            v-if="
                              workout.data.sensation == 0 ||
                              workout.data.sensation == null
                            "
                          >
                            ?
                          </div>
                        </th>
                        <td class="align-middle">
                          <b>{{ workout.data.titre }}</b>
                        </td>
                        <td class="align-middle">
                          <span class="badge rounded-pill bg-light text-dark">{{
                            workout.data.type
                          }}</span>
                        </td>
                        <td class="align-middle">
                          <small>
                            {{ dateFromTimestamp(workout.data.date) }}</small
                          >
                        </td>
                        <td class="align-middle">
                          <i
                            v-if="workout.data.status"
                            class="fas fa-check"
                          ></i>
                          <i
                            v-if="!workout.data.status"
                            class="fas fa-times"
                          ></i>
                        </td>
                        <td class="align-middle">
                          {{ getMinutes(workout.data.duree_workout) }}'
                        </td>
                        <td class="align-middle">
                          <div v-if="workout.data.status">
                            <strong>{{ getArrondi(workout.data.kcal) }}</strong
                            >kcal
                          </div>
                        </td>
                        <td class="align-middle">
                          {{ workout.data.commentaire }}
                        </td>
                        <td class="align-middle">
                          <button
                            type="button"
                            class="btn btn-warning"
                            v-if="workout.data.bpm != null"
                            @click="showModalBpm(workout.data.bpm)"
                          >
                            <i class="fas fa-heartbeat"></i>
                          </button>
                        </td>
                        <td v-if="JSON.parse(isAdmin)">
                          <button
                            type="button"
                            class="btn btn-danger"
                            @dblclick="deleteWorkoutHistoric(workout.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div>
                  <i class="fas fa-pepper-hot"></i>
                  <span class="d-none d-sm-block">Nutrition</span>
                </div>
              </template>

              <div v-if="user != 0">
                <div class="container">
                  <div class="row">
                    <div class="col-3">
                      <div
                        class="alert alert-secondary text-center"
                        role="alert"
                      >
                        <h4>Nutritional analysis</h4>
                        <p class="fs-1">
                          <span class="fw-bolder">{{ user.besoin_kcal }}</span
                          ><span class="fw-lighter">kcal</span>
                        </p>
                        <div>
                          <input
                            type="range"
                            class="form-range"
                            min="-30"
                            max="30"
                            @change="besoinJournalierKcal()"
                            v-model="user.ajust_nutrition"
                            step="1"
                            id="ajustnutritionslider"
                          />
                          <p>
                            Ajust nutrition:
                            <strong>{{ user.ajust_nutrition }}%</strong>
                          </p>
                          <hr />
                          <h6>Macro-nutriments</h6>
                          <div class="text-start mb-2">
                            <div class="mb-1">
                              <strong>{{ getMacros()["proteing"] }}g</strong>
                              protein
                              <span class="float-end"
                                ><small
                                  >{{ getMacros()["proteinkcal"] }}kcal</small
                                ></span
                              >
                            </div>
                            <div class="progress" style="height: 20px">
                              <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                style="width: 25%"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                25%
                              </div>
                            </div>
                          </div>
                          <div class="text-start mb-2">
                            <div class="mb-1">
                              <strong>{{ getMacros()["glucideg"] }}g</strong>
                              glucide
                              <span class="float-end"
                                ><small
                                  >{{ getMacros()["glucidekcal"] }}kcal</small
                                ></span
                              >
                            </div>
                            <div class="progress" style="height: 20px">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style="width: 45%"
                                aria-valuenow="45"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                45%
                              </div>
                            </div>
                          </div>
                          <div class="text-start mb-2">
                            <div class="mb-1">
                              <strong>{{ getMacros()["lipideg"] }}g</strong>
                              lipid
                              <span class="float-end"
                                ><small
                                  >{{ getMacros()["lipidekcal"] }}kcal</small
                                ></span
                              >
                            </div>
                            <div class="progress" style="height: 20px">
                              <div
                                class="progress-bar bg-danger"
                                role="progressbar"
                                style="width: 30%"
                                aria-valuenow="30"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                30%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="alert alert-warning" role="alert">
                        <h4 class="mb-3 text-center">Diet type</h4>
                        <b-form-group>
                          <b-form-checkbox-group
                            v-model="user.regime"
                            :options="regimes"
                          ></b-form-checkbox-group>
                        </b-form-group>
                        <button
                          class="btn btn-light btn-small mt-2"
                          @click="regenerateMenu()"
                          type="button"
                        >
                          <span
                            v-if="regenerating_menu"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Regenerate Menu
                        </button>
                      </div>
                    </div>
                    <div class="col-9">Meal plan (a venir)</div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div>
                  <i class="uil uil-comment-alt-message font-size-16"></i>
                  <span class="d-none d-sm-block">Tickets</span>
                </div>
              </template>
              <div class="min-vh-100">
                <h2>All tickets</h2>
                <hr class="my-4" />
                <chat
                  :user="idUser"
                  :key="'chatCompononent' + forceReloadChat"
                ></chat>
              </div>
            </b-tab>
            <b-tab v-if="JSON.parse(isAdmin)">
              <template v-slot:title>
                <i class="uil uil-euro-circle font-size-16"></i>
                <span class="d-none d-sm-block">Pay</span>
              </template>
              <div class="min-vh-100">
                <h2>User payments</h2>
                <hr class="my-4" />
                <div class="container">
                  <div class="row">
                    <div class="col-3">
                      <div class="alert alert-success text-center" role="alert">
                        <h2>Total:</h2>
                        <p class="fs-1">{{ getTotalPay() }}€</p>
                        <hr />
                        <i class="fas fa-info-circle"></i> This total not
                        include App Subscriptions.
                      </div>
                    </div>
                    <div class="col-9">
                      <h3>Payments:</h3>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mb-2"
                        @click="showModalNewPayment()"
                      >
                        Add new payment
                      </button>
                      <div class="mt-3">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Amount Due</th>
                              <th scope="col">Amount Paid</th>
                              <th scope="col">Status</th>
                              <th scope="col">Source</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(paiement, index) in paiements"
                              :key="'paiement-' + index"
                            >
                              <th scope="row">{{ index }}</th>
                              <td>
                                {{ paiement.timestamp | moment("DD/MM/YYYY") }}
                              </td>
                              <td>{{ paiement.amount_due / 100 }}€</td>
                              <td>
                                <strong
                                  >{{ paiement.amount_paid / 100 }}€</strong
                                >
                              </td>
                              <td>
                                <span
                                  class="badge"
                                  :class="
                                    paiement.status == 'succeeded'
                                      ? 'bg-success'
                                      : 'bg-danger'
                                  "
                                  >{{ paiement.status }}</span
                                >
                              </td>
                              <td>
                                <small>{{ paiement.methode }}</small>
                              </td>
                              <td v-if="JSON.parse(isAdmin)">
                                <button
                                  type="button"
                                  v-if="paiement.methode == 'Virement'"
                                  class="btn btn-danger btn-sm"
                                  @dblclick="deleteVirement(paiement.id)"
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      <div
                        class="alert alert-secondary"
                        role="alert"
                        v-if="appPayments != null"
                      >
                        <p>Subscriptions on App:</p>
                        <div
                          v-if="
                            Object.keys(appPayments.subscriber.subscriptions)
                              .length == 0
                          "
                        >
                          No subscriptions detected
                        </div>
                        <div
                          v-if="
                            Object.keys(appPayments.subscriber.subscriptions)
                              .length > 0
                          "
                        >
                          <ul>
                            <li
                              v-for="(subscription, name, index) in appPayments
                                .subscriber.subscriptions"
                              :key="'subscription-' + index"
                            >
                              <strong>{{ name }}</strong> ({{
                                subscription.store
                              }})<br />Original purchase date:
                              {{ subscription.original_purchase_date }} /
                              Expires date: {{ subscription.expires_date }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <!-- Nav tabs -->
          <!-- Tab content -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
